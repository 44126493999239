body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MyHelveticaCE-Cond';
  src: url('fonts/helveticace-cond.woff') format('woff'), /* Modern Browsers */
     url('fonts/helveticace-cond.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MyHelveticaCE-Cond';
  src: url('fonts/helveticace-condbold.woff') format('woff'), /* Modern Browsers */
     url('fonts/helveticace-condbold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Planer;
  src: url('fonts/planer.woff') format('woff'), /* Modern Browsers */
     url('fonts/planer.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Planer;
  src: url('fonts/planer-bold.woff') format('woff'), /* Modern Browsers */
     url('fonts/planer-bold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: bold;
  font-style: normal;
}
