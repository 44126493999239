.interactive_features{
  max-width: 245px;
  margin-left: 80px;
}
.dateAndForm{
  padding: 12px 80px;
}

@media only screen and (max-width: 767px) {
  .interactive_features{
    max-width: 100%;
  }
  .dateAndForm{
    padding: 12px 0px;
  }
}
