.footerImage{
  float: right;
}
.footerText{
  display: inline-block;
  width: calc(100% - 100px);
}

@media only screen and (max-width: 600px) {
  .footerImage{
    float: none;
    text-align: center;
  }
  .footerText{
    width: 100%;
  }
}
