.body{
  padding: 10px 40px;
  background: rgb(255,255,255);
  height: calc(100% - 80px);
  overflow-x: hidden;
  min-height: calc(100vh - 80px);
}
@media only screen and (max-width: 500px) {
  .body{
    padding: 10px 20px;
  }
}
@media only screen and (max-width: 1025px) and (max-height: 769px) and (min-width: 1022px) and (min-height: 762px) {
  .body{
    min-height: 768px;
  }
}

@media only screen and (max-width: 769px) and (max-height: 1025px) and (min-width: 762px) and (min-height: 1022px) {
  .body{
    min-height: 1024px;
  }
}

@media only screen and (max-height: 500px) {
  .body{
    min-height: 900px;
  }
}

.hamburgerMenu{
  position: absolute;
  width: 220px;
  padding: 70px 20px 30px 20px;
  border-radius: 0px 0px 0px 10px;
  right: 0;
  top: 0;
  background-color: white;
  transition: all 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.my-navbar{
  font-family: Planer;
}
.nav > li{
  height: 100%;
  margin: 0px 20px;
}
.nav > li > a:focus, .nav > li > a:hover{
  background-color: unset;
}
.my-navbar .navbar-nav > li > a{
  font-weight: bold;
  font-size: 16px;
  position: relative;
  top: 50%;
  transform: translate(0,-50%);
}
#menu > li > a{
  text-decoration: none;
}

.my-col{
  flex: 0 0 50%;
  width: 50%;
}
@media only screen and (max-height: 880px) {
  .my-col{
    flex: 0 0 50%;
    width: 50%;
  }
}
@media only screen and (max-height: 780px) {
  .my-col{
    flex: 0 0 50%;
    width: 50%;
  }
}
@media only screen and (max-height: 730px) {
  .my-col{
    flex: 0 0 50%;
    width: 50%;
  }
}
@media only screen and (max-height: 700px) {
  .my-col{
    flex: 0 0 50%;
    width: 50%;
  }
}
@media only screen and (max-width: 1024px) {
  .my-col{
    flex: 0 0 70%;
    width: 70%;
  }
}
@media only screen and (max-width: 700px) {
  .my-col{
    flex: 0 0 100%;
    width: 100%;
  }
}

.my-container{
  margin-left: 15px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1024px) {
  .my-container{
    margin-left: 0px;
  }
}
@media only screen and (max-width: 990px) {
  .my-container{
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .my-container{
    margin-bottom: 40px;
  }
}

.navbar-height{
  height: 180px;
  float: right;
  flex-direction: row;
}
#menuToggle{
  display: none;
}
.mobile-menu{
  display: none;
}
@media only screen and (max-width: 900px) {
  .navbar-height{
    display: none;
    /*height: initial;
    float: initial;
    text-align: center;*/
  }
  .navbar-header{
    margin: 0 auto;
  }
  .navbar-brand{
    float: initial;
    display: block;
  }
  .navbar-brand > img{
    display: block;
  /*	margin-left: 50%;
    transform: translate(-50%, 0);*/
  }
  #menuToggle{
    display: block;
  }
  .mobile-menu{
    display: block;
  }
}

.footer {
   position: fixed;
   border-top: 4px solid #681870;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: white;
   color: #767879;
   font-family: Planer;
   padding: 10px 40px;
}
.footer1 {
  font-size: 16px;
}
.footer2 {
  font-size: 16px;
}

.title-margin{
  margin-bottom: 20px;
}
.title{
  font-size: 44px;
}
.gilead-logo{
  text-align: right;
}
@media only screen and (max-width: 800px) {
  .title-margin{
    margin-bottom: 0px;
  }
  .title{
    font-size: 35px;
  }
  .gilead-logo{
    margin-top: 15px;
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  .footer {
     position: relative;
     left: -20px;
     bottom: -10px;
     width: 100vw;
  }
  .footer1 {
    font-size: 14px;
  }
  .footer2 {
    font-size: 14px;
  }
}


#menuToggle
{
position: absolute;
top: 27px;
right: 25px;

z-index: 1;

-webkit-user-select: none;
user-select: none;
}

#menuToggle input
{
display: block;
width: 40px;
height: 32px;
position: absolute;
top: -7px;
left: -5px;

cursor: pointer;

opacity: 0; /* hide this */
z-index: 2; /* and place it over the hamburger */

-webkit-touch-callout: none;
}

/*
* Just a quick hamburger
*/
#menuToggle span
{
display: block;
width: 33px;
height: 4px;
margin-bottom: 5px;
position: relative;

background: #cdcdcd;
border-radius: 3px;

z-index: 1;

transform-origin: 4px 0px;

transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
}

#menuToggle span:first-child
{
transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
transform-origin: 0% 100%;
}

/*
* Transform all the slices of hamburger
* into a crossmark.
*/
#menuToggle input:checked ~ span
{
opacity: 1;
transform: rotate(45deg) translate(-2px, -1px);
background: #cdcdcd;
}

/*
* But let's hide the middle one.
*/
#menuToggle input:checked ~ span:nth-last-child(3)
{
opacity: 0;
transform: rotate(0deg) scale(0.2, 0.2);
}

/*
* Ohyeah and the last one should go the other direction
*/
#menuToggle input:checked ~ span:nth-last-child(2)
{
opacity: 1;
transform: rotate(-45deg) translate(0, -1px);
}

/*
* Make this absolute positioned
* at the top left of the screen
*/
#menu
{
position: absolute;
width: 240px;
margin: -100px 0 0 0;
padding: 30px;
padding-top: 125px;
right: -100px;

background-color: white;
border: 1px solid #e1a937;
border-radius: 10px;

list-style-type: none;
-webkit-font-smoothing: antialiased;
/* to stop flickering of text in safari */

transform-origin: 0% 0%;
transform: translate(100%, 0);

transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

font-family: Planer;
font-weight: bold;
}

#menu li
{
padding: 10px 0;
font-size: 16px;
}

/*
* And let's fade it in from the left
*/
#menuToggle input:checked ~ ul
{
transform: none;
opacity: 1;
}

/*
@media screen and (max-width: 768px) {
#menu {
transform: none;
opacity: 0;

transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}
}*/

.my-modal-.body{
  width: 90vw;
  max-width: 600px;
  border-radius: 0px 0px 15px 15px;
  font-size: 18px;
  background-color: #fcfcfc;
  padding: 10px 60px;
  font-family: MyHelveticaCE-Cond;
}
@media screen and (max-width: 600px) {
  .my-modal-body {
  padding: 10px 20px;
  }
}

.title-text{
  font-size: 24px;
}
@media only screen and (max-width: 454px) {
  .title-text{
    font-size: 20px;
  }
}
