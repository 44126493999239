*{
  box-sizing: border-box;
}

.MyPlayer {
  position: relative;
  width: 100%;
  height: 100%;
}

.centerPlay{
  font-size: 100px;
}
@media only screen and (max-width: 600px) {
  .centerPlay{
    font-size: 60px;
  }
}
@media only screen and (max-height: 600px) {
  .centerPlay{
    font-size: 60px;
  }
}

.fullscreen{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.volume{
  clip-path: polygon(0 -5px, 25px -5px, 25px 100%, 0 100%);
  transition: all 0.3s;
  margin-left: 16px;
}

.volume:hover{
  clip-path: polygon(0 -5px, 100% -5px, 100% 100%, 0 100%);
}

.volume .volumeControl{
  opacity: 0;
  display: inline-block;
  background-color: #0000009e;
  margin-left: 10px;
  margin-right: 10px;
  padding: 4px 10px;
  position: relative;
  top: 0px;
  transition: all 0.3s;
  border-radius: 15px;
  width: 0px;
}

.volume:hover .volumeControl{
  opacity: 0.90;
  width: 155px;
}

.controlsContainer{
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 15px;
  color: white;
  font-size: 24px;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0.7) 100%);
  padding-top: 35px;
}
